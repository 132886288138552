import { normalizeUrl } from '@utils/normalizeUrl';

import { getSharedEnvironmentVariables } from './environmentVariables';
import pickFirst from './pickFirst';

const sharedEnvironmentVariables = getSharedEnvironmentVariables();

const checkSlashes = (value: string) => {
  if (!value) {
    return value;
  }
  return value.endsWith('/') ? value : `${value}/`;
};

export const buildHrefLang = ({
  locale,
  mappers,
  query,
  page
}: {
  locale: TLocale;
  /** Array of mapper functions */
  mappers: ((e: TLocale) => string)[];
  query?: string | string[];
  /** Number of pagination if applicable */
  page?: string | string[];
}) => {
  const baseUrl = normalizeUrl(sharedEnvironmentVariables.WEBSITE_URL ?? '/');
  const mappedUrl = checkSlashes(
    mappers
      .map((mapFunction: (e: TLocale) => string) => mapFunction(locale))
      .join('/')
  );
  const queryString = query ? checkSlashes(pickFirst(query)) : '';
  const pagination = page ? `?page=${pickFirst(page)}` : '';

  return `${baseUrl}${locale}/${mappedUrl}${queryString}${pagination}`;
};
